<template>
  <FranchiseClinicProductsList />
</template>

<script>
import FranchiseClinicProductsList from "@/views/components/FranchiseClinicProductsList";

export default {
  name: "FranchiseProductsList",
  components: {
    FranchiseClinicProductsList
  }
};
</script>
